import React from 'react'
import Layout from "../components/layout";
import {Link, graphql} from "gatsby";

export default function Template({data}) {

    const { markdownRemark: post } = data

    return (
        <Layout>
            <h1>{post.frontmatter.title}</h1>
            <div dangerouslySetInnerHTML={{__html: post.html}} />

            <Link to='/blog'>Back to posts</Link>
        </Layout>
    )
}


export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`